<template>
  <div>
    <b-modal
      id="filter-modal"
      ref="filter-modal"
      size="lg"
      :title="this.$t('modals.filterDevice.text')"
    >

      <!-- Type -->
      <div class="row">
        <div class="col-6 col-lg-6">
          <label for="reference">{{$t("modals.reference.text")}}</label>
          <b-form-input
            id="reference"
            v-model="devicesFilter.ref"
            class="mb-1"
            :placeholder="$t('modals.reference.text')"
          ></b-form-input>
        </div>
        <div class="col-6 col-lg-6">
          <label for="typeSh">{{ $t("modals.type.text") }}</label>
          <b-form-select
            id="typeSh"
            v-model="devicesFilter.type"
            :options="typesFilterData"
            class="mb-1"
          ></b-form-select>
        </div>
      </div>
      <!-- Vaccin Types -->
      <div class="row">
        <div class="col-6 col-lg-6" data-app>
          <label for="vaccinSh">{{ $t("modals.vaccinType.text") }}</label>
          <v-select
            id="vaccinSh"
            v-model="vaccintypes"
            :items="vaccintypesFilterData"
            :menu-props="{ maxHeight: '200' }"
            item-text="text"
            item-value="value"
            multiple
          ></v-select>
        </div>
        <div class="col-6 col-lg-6" data-app>
          <label for="stypeSh">{{ $t("modals.stockType.text") }}</label>
          <v-select
            id="stypeSh"
            v-model="stockTypes"
            :items="stockTypesFilterData"
            :menu-props="{ maxHeight: '200' }"
            item-text="text"
            item-value="value"
            multiple
          ></v-select>
        </div>
      </div>
      <!-- Region -->
      <div class="row">
        <div class="col-6 col-lg-6" data-app>
          <label for="regionSh">{{ $t("modals.region.text") }}</label>
          <v-select
            id="regionSh"
            v-model="regions"
            :items="regionsFilterData"
            :menu-props="{ maxHeight: '200' }"
            item-text="text"
            item-value="value"
            multiple
          ></v-select>
        </div>
        <!-- Province -->
        <div class="col-6 col-lg-6" data-app>
          <label for="provinceSh">{{ $t("modals.province.text") }}</label>
          <v-select
            id="provinceSh"
            v-model="provinces"
            :items="provincesFilterData"
            :menu-props="{ maxHeight: '200' }"
            item-text="text"
            item-value="value"
            multiple
          ></v-select>
        </div>
      </div>

      <template #modal-footer="{ cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="search">
              {{ $t("buttons.filter.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  provincesMethods,
  provincesComputed,
} from "@/state/helpers";
export default {
  props: ["regionsData", "vaccintypesData"],
  created() {},
  computed: {
    ...provincesComputed,
  },
  data() {
    return {

      typesFilterData: [
        { value: null, text: "--All--" },
        { value: 1, text: "REFRIGERATEUR" },
        { value: 2, text: "CONGELATEUR" },
        { value: 3, text: "ARMOIRES_FRIGORIFIQUE" },
        { value: 4, text: "CHAMBRE_FROIDE" },
        { value: 5, text: "CAMION" },
      ],
      stockTypesFilterData: [
        { value: null, text: "--All--" },
        { value: "24 H", text: "24 H" },
        { value: "VIDE", text: "VIDE" },
      ],
      regionsFilterData: [{ value: null, text: "--All--" }],
      provincesFilterData: [{ value: null, text: "--All--" }],
      vaccintypesFilterData: [{ value: null, text: "--All--" }],

      devicesFilter: {
        serial: null,
        ref: null,
        name: null,
        type: null,
        stockTypes: null,
        regions: null,
        provinces: null,
        vaccintypes: null,
      },
      stockTypes: [null],
      vaccintypes: [null],
      regions: [null],
      provinces: [null],
    };
  },
  watch: {
    region(newVal){
      Object.assign(this.deviceAdd, {region: newVal, province: null});
      this.retrieveProvinces(newVal)
    },
    
    vaccintypes(newVal) {
      let lenVaccintypes = newVal.length;

      if (!newVal[lenVaccintypes - 1] && lenVaccintypes > 1) {
        this.vaccintypes = [null];
        this.devicesFilter.vaccintypes = null;
      } else if (newVal[lenVaccintypes - 1] && newVal.includes(null)) {
        var filteredVaccintypes = this.vaccintypes.filter(function (item) {
          return item != null;
        });
        this.vaccintypes = filteredVaccintypes;
      } else if (newVal[lenVaccintypes - 1] && !newVal.includes(null)) {
        this.devicesFilter.vaccintypes = newVal;
      } else if (newVal.length == 0) {
        this.vaccintypes = [null];
        this.devicesFilter.vaccintypes = null;
      }
    },
    stockTypes(newVal) {
      let lenTypes = newVal.length;

      if (!newVal[lenTypes - 1] && lenTypes > 1) {
        this.stockTypes = [null];
        this.devicesFilter.stockTypes = null;
      } else if (newVal[lenTypes - 1] && newVal.includes(null)) {
        var filteredStockTypes = this.stockTypes.filter(function (item) {
          return item != null;
        });
        this.stockTypes = filteredStockTypes;
      } else if (newVal[lenTypes - 1] && !newVal.includes(null)) {
        this.devicesFilter.stockTypes = newVal;
      } else if (newVal.length == 0) {
        this.stockTypes = [null];
        this.devicesFilter.stockTypes = null;
      }
    },
    regions(newVal) {
      let lenRegions = newVal.length;

      if (!newVal[lenRegions - 1] && lenRegions > 1) {
        this.regions = [null];
        this.devicesFilter.regions = null;
      } else if (newVal[lenRegions - 1] && newVal.includes(null)) {
        var filteredRegions = this.regions.filter(function (item) {
          return item != null;
        });
        this.regions = filteredRegions;
      } else if (newVal[lenRegions - 1] && !newVal.includes(null)) {
        this.devicesFilter.regions = newVal;
      } else if (newVal.length == 0) {
        this.regions = [null];
        this.devicesFilter.regions = null;
      }
      this.retrieveProvinces(newVal);
    },
    provinces(newVal) {
      let lenProvinces = newVal.length;

      if (!newVal[lenProvinces - 1] && lenProvinces > 1) {
        this.provinces = [null];
        this.devicesFilter.provinces = null;
      } else if (newVal[lenProvinces - 1] && newVal.includes(null)) {
        var filteredProvinces = this.provinces.filter(function (item) {
          return item != null;
        });
        this.provinces = filteredProvinces;
      } else if (newVal[lenProvinces - 1] && !newVal.includes(null)) {
        this.devicesFilter.provinces = newVal;
      } else if (newVal.length == 0) {
        this.provinces = [null];
        this.devicesFilter.provinces = null;
      }
    },
    


    getProvinces(newVal) {
      this.chargeProvinces(newVal.results, this.regions);
    },
    vaccintypesData(newVal) {
      this.chargeVaccintypes(newVal);
    },
    regionsData(newVal) {
      this.chargeRegions(newVal);
    },
  },
  methods: {
    ...provincesMethods,
    chargeVaccintypes(vaccintypes) {
      let vData = vaccintypes;
      vData.map((obj) => {
          this.vaccintypesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeRegions(regions) {
      let rData = regions;
      rData.map((obj) => {
        this.regionsFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeProvinces(provinces, regions) {
      this.provincesFilterData = [{ value: null, text: "--All--" }];
      let pData = provinces;
      pData.map((obj) => {
        if(regions.includes(obj.region)) this.provincesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    retrieveProvinces(regions) {
      if (this.getProvinces.results)
        return this.chargeProvinces(this.getProvinces.results, regions);
      else
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },
    cancel() {
      return;
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    clear() {
      this.devicesFilter = {
        serial: null,
        ref: null,
        name: null,
        type: null,
        stockTypes: null,
        vaccintypes: null,
        regions: null,
        provinces: null,
      };
      this.vaccintypes = [null];
      this.stockTypes = [null];
      this.regions = [null];
      this.provinces = [null];
    },
    search(){
      let data = {...this.devicesFilter}
      let filtredParams = this.cleanObject(data);
      this.$emit("filter", filtredParams);
    }
    
  },
};
</script>